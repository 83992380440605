import "core-js/modules/es.array.push.js";
const TABLE_VERTICAL_MODE = 'VERTICAL';
import TopTitle from '@/views/print/components/TopTitle.vue';
export default {
  components: {
    TopTitle
  },
  props: {
    logo: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    colNum: {
      type: Number,
      default: 3
    },
    labelWidth: {
      type: [String, Number],
      default: ''
    },
    header: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: TABLE_VERTICAL_MODE
    },
    columns: {
      type: Array,
      required: true
    },
    data: {
      type: [Array, Object],
      required: true
    }
  },
  data() {
    return {
      align: 'center',
      colLen: 24,
      minWidth: 40
    };
  },
  computed: {
    isVertical() {
      return this.mode == TABLE_VERTICAL_MODE;
    },
    getTableHead() {
      if (this.isVertical) return false;
      const hasChild = this.columns.some(it => it.attrs.children);
      return hasChild;
    },
    getColSpan() {
      return rows => this.colNum == 1 ? this.colLen - 1 : this.colNum != rows.length ? this.colLen - rows.length : 0;
    },
    getColumns() {
      if (this.isVertical) {
        const data = [...this.columns];
        const res = [];
        let index = 0;
        while (index < data.length) {
          res.push({
            rows: this.columns.slice(index, this.colNum + index)
          });
          index += this.colNum;
        }
        return res;
      }
      return this.columns;
    },
    getData() {
      const data = this.isVertical ? Array.isArray(this.data) ? this.data[0] : this.data : this.data;
      return data;
    },
    getHeadCount() {
      let count = 1;
      this.columns.forEach(it => {
        const {
          children
        } = it.attrs;
        if (children && children.length) {
          count += 1;
          children.forEach(g => {
            if (g.attrs.children && g.attrs.children.length) {
              count += 1;
            }
          });
        }
      });
      return count;
    }
  }
};