import BoatPersonal from '@/views/print/pages/BoardName/Team';
import { defaultState, rules } from '../config';
import { ColumnsShipName as Columns } from '../tableConfig';
export default {
  components: {
    BoatPersonal
  },
  data() {
    return {
      rules,
      Columns,
      visible: false,
      form: defaultState(),
      id: '',
      activeName: '',
      tabList: [{
        label: '锁定名单',
        value: 'DEFAULT'
      }, {
        label: '最终名单',
        value: 'FINAL'
      }, {
        label: '新增名单',
        value: 'NEW'
      }, {
        label: '取消名单',
        value: 'CANCEL'
      }]
    };
  },
  computed: {
    getBoatType() {
      return this.activeName;
    }
  },
  methods: {
    fetchData(params) {
      this.$refs.boatRef.fetchBoatMembers(params);
    },
    handleExport() {
      this.$refs.boatRef.handleExport();

      // const query = {
      //   id: this.id,
      //   planId: this.id,
      //   type: 'plan',
      //   component: 'TeamName',
      //   planType: 'T_TOURIST',
      //   gateUrl: 'team',
      //   isRedirect: true,
      // }
      // // print-bill?id=58454&planId=37562&component=TeamName&planType=T_TOURIST&gateUrl=team
      // const routeUrl = this.$router.resolve({
      //   path: '/print-bill',
      //   query,
      // })
      // window.open(routeUrl.href, '_blank')
    },
    show({
      id
    }) {
      this.visible = true;
      this.id = id;
      this.$nextTick(() => {
        this.fetchData({
          id
        });
      });
    },
    hide() {
      this.visible = false;
      this.form = defaultState();
    }
  }
};