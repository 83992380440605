/*
 * @Author: HelloMan
 * @Date: 2021-08-11 09:28:09
 * @LastEditors: HelloMan
 * @LastEditTime: 2021-10-08 11:54:21
 * @Description:
 */
const IDValidator = require('id-validator');
const GB2260 = require('../json/GB2260'); // 添加了一些老的区域
import { parseDate } from '@/utils';
import { SELECTOR } from '@/config/select';
const DIRECTLY_CITY_KEY = [11, 12, 31, 50];
const PROVINCE_LIST = {
  11: ['北京市', '市辖区', ''],
  12: ['天津市', '市辖区', ''],
  13: ['河北省', '市', ''],
  14: ['山西省', '市', ''],
  15: ['内蒙古', '市,盟', 'null'],
  21: ['辽宁省', '市', ''],
  22: ['吉林省', '市,州', ''],
  23: ['黑龙江', '市,地区', ''],
  31: ['上海市', '市辖区', ''],
  32: ['江苏省', '市', ''],
  33: ['浙江省', '市', ''],
  34: ['安徽省', '市', ''],
  35: ['福建省', '市', ''],
  36: ['江西省', '市', ''],
  37: ['山东省', '市', ''],
  41: ['河南省', '市', ''],
  42: ['湖北省', '市,州', ''],
  43: ['湖南省', '市,州', ''],
  44: ['广东省', '市', ''],
  45: ['广西', '市', ''],
  46: ['海南省', '市,县', 'null'],
  50: ['重庆市', '市辖区,市郊县', 'null'],
  51: ['四川省', '市,州', ''],
  52: ['贵州省', '市,州', ''],
  53: ['云南省', '市,州', ''],
  54: ['西藏', '市,区', ''],
  61: ['陕西省', '市', ''],
  62: ['甘肃省', '市,州', ''],
  63: ['青海省', '市,州', ''],
  64: ['宁夏', '市', ''],
  65: ['新疆', '市,州,区', ''],
  71: ['台湾', '区', ''],
  81: ['香港', '区', ''],
  82: ['澳门', '区', '']
};

// const PRO_LABEL = '省'
const CITY_LABEL = '市';
// const DIS_LABEL = '区'

const Validator = new IDValidator(GB2260);

// 验证身份证
export const validCard = (id, number = 18) => {
  id = id && id.trim();
  return Validator.isValid(id, number);
};
const hasProvince = addr => {
  return addr.indexOf('省') > 0;
};
const getProvince = id => {
  return PROVINCE_LIST[id][0];
};
const getCityIndex = (id, addr) => {
  const cityArr = PROVINCE_LIST[id][1].split(',');
  let res = -1;
  if (DIRECTLY_CITY_KEY.includes(Number(id))) {
    res = addr.indexOf(CITY_LABEL);
  } else {
    cityArr.forEach(val => {
      const idx = addr.indexOf(val);
      if (idx > 0 && addr.indexOf(val) == addr.lastIndexOf(val)) {
        res = idx;
      }
    });
  }
  return res;
};
const getCity = (id, addr) => {
  if (addr.indexOf('台湾') > 0) {
    return '台湾省';
  }
  if (DIRECTLY_CITY_KEY.includes(Number(id))) {
    // 重庆下面有市辖县
    if (id == 50) {
      return addr.indexOf('市辖县') > -1 ? '市辖县' : '市辖区';
    }
    return '市辖区';
  } else {
    const idx = getCityIndex(id, addr);
    const cityLabel = addr.substr(0, idx + 1);

    // 省
    if (hasProvince(cityLabel)) {
      return cityLabel.slice(addr.indexOf('省') + 1);
    } else {
      return cityLabel.slice(addr.indexOf('区') + 1);
    }
  }
};
const getDistrict = (id, addr) => {
  const idx = getCityIndex(id, addr);
  return addr.slice(idx + 1);
};
const processLocation = (id, res) => {
  const {
    addr
  } = res;
  if (!addr) return;
  const ids = id.substr(0, 2);
  const province = getProvince(ids);
  let city = getCity(ids, addr);
  let district = getDistrict(ids, addr);
  return {
    ...res,
    province,
    city,
    district
  };
};

// 获取信息
export const getIdCardInfo = (id, number = 18) => {
  const code = id.length == 15 ? id.charAt(14) : id.charAt(16);
  const res = Validator.getInfo(id, number);
  if (!res) return {
    sex: 0,
    addr: '未知',
    birth: parseDate(new Date())
  };
  return processLocation(id, {
    ...res,
    sex: code % 2 === 0 ? 1 : 0
  });
};
export const getBirthday = number => {
  if (!number) return;
  const birth = number.slice(6, 14);
  const year = birth.slice(0, 4);
  const month = birth.slice(4, 6);
  const day = birth.slice(6);
  return {
    year,
    month,
    day
  };
};

// 根据出生年月日计算实际年龄
export const calculateAgeByBirth = birthDate => {
  if (!birthDate) return;
  const today = new Date();
  const birth = new Date(birthDate);
  let age = today.getFullYear() - birth.getFullYear();
  const monthDifference = today.getMonth() - birth.getMonth();

  // 如果当前月份小于出生月份，或者当前月份等于出生月份但日期小于出生日期，年龄减一
  if (monthDifference < 0 || monthDifference === 0 && today.getDate() < birth.getDate()) {
    age--;
  }
  return age;
};

// 身份证信息
export const getCardTypeLabel = val => {
  const findItem = SELECTOR.cardType.find(it => it.value == val);
  return findItem ? findItem.label : val;
};