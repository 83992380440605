var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.form ? _c("div", {
    staticClass: "resource-plan-form print-page"
  }, [_vm._m(0), _c("div", [_c("h3", {
    staticClass: "tar"
  }, [_vm._v("下订单日期：" + _vm._s(_vm.getOrderTime))])]), _c("table", [_c("tbody", [_c("tr", [_c("th", {
    style: _vm.getFirstColStyle
  }, [_vm._v("组团社(团号)")]), _c("td", [_vm._v(" " + _vm._s(_vm.form.planNumber) + " ")]), _c("th", {
    staticStyle: {
      width: "8%"
    }
  }, [_vm._v("客服")]), _c("td", {
    staticStyle: {
      width: "14%"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.getCustomerInfo))])])])])]), _c("table", [_c("tbody", [_c("tr", [_c("th", {
    style: _vm.getFirstColStyle
  }, [_vm._v("团队人数")]), _c("td", {
    staticStyle: {
      width: "20%"
    }
  }, [_c("div", {
    staticClass: "flex"
  }, [_vm._v(" " + _vm._s(_vm.form.adultCount) + " "), _c("span", {
    staticClass: "pl5 pr5"
  }, [_vm._v("大")]), _vm._v(" " + _vm._s(_vm.form.childCount) + " "), _c("span", {
    staticClass: "pl5 pr5"
  }, [_vm._v("小")]), _vm._v(" " + _vm._s(_vm.form.accompanyCount) + " "), _c("span", {
    staticClass: "pl5 pr5"
  }, [_vm._v("陪")])])]), _c("th", {
    staticStyle: {
      width: "8%"
    }
  }, [_vm._v("用房数")]), _c("td", {
    staticStyle: {
      width: "40%"
    }
  }, [_c("div", {
    staticClass: "flex"
  }, [_vm._v(" " + _vm._s(_vm.form.hotelStandardCount) + " "), _c("span", {
    staticClass: "pl5 pr5"
  }, [_vm._v("标")]), _vm._v(" " + _vm._s(_vm.form.hotelBigCount) + " "), _c("span", {
    staticClass: "pl5 pr5"
  }, [_vm._v("大")]), _vm._v(" " + _vm._s(_vm.form.hotelThreeCount) + " "), _c("span", {
    staticClass: "pl5 pr5"
  }, [_vm._v("三")]), _vm._v(" " + _vm._s(_vm.form.hotelChildCount) + " "), _c("span", {
    staticClass: "pl5 pr5"
  }, [_vm._v("亲子")]), _vm._v(" " + _vm._s(_vm.form.hotelRemarks) + " ")])]), _c("th", {
    staticStyle: {
      width: "8%"
    }
  }, [_vm._v("客源地")]), _c("td", [_vm._v(" " + _vm._s(_vm.form.source) + " ")])])])]), _c("table", [_c("tbody", [_c("tr", [_c("th", {
    style: _vm.getFirstColStyle,
    attrs: {
      rowspan: _vm.form.itineraries.length + 1
    }
  }, [_vm._v(" 行程 ")]), _c("th", {
    staticStyle: {
      width: "12%"
    }
  }, [_vm._v("日期")]), _c("th", [_vm._v("行程")]), _c("th", {
    staticStyle: {
      width: "20%"
    }
  }, [_vm._v("酒店地区")]), _c("th", {
    staticStyle: {
      width: "22%"
    }
  }, [_vm._v("含餐")])]), _vm._l(_vm.form.itineraries, function (item, day) {
    return _c("tr", {
      key: day
    }, [_c("td", [_vm._v(" " + _vm._s(item.date) + " ")]), _c("td", [_vm._v(" " + _vm._s(item.desc) + " ")]), _c("td", [_vm._v(" " + _vm._s(item.hotel) + " ")]), _c("td", [_vm._v(" " + _vm._s(item.restaurant) + " ")])]);
  })], 2)]), _c("table", [_c("tbody", [_c("tr", [_c("th", {
    style: _vm.getFirstColStyle
  }, [_vm._v("订餐")]), _c("td", [_vm._v(" " + _vm._s(_vm.form.restaurant) + " ")])]), _c("tr", [_c("th", {
    style: _vm.getFirstColStyle
  }, [_vm._v("订车")]), _c("td", [_vm._v(" " + _vm._s(_vm.getBusInfo) + " ")])]), _c("tr", [_c("th", {
    style: _vm.getFirstColStyle
  }, [_vm._v("订酒店")]), _c("td", [_vm._v(" " + _vm._s(_vm.form.hotel) + " ")])]), _c("tr", [_c("th", {
    style: _vm.getFirstColStyle
  }, [_vm._v("订船票")]), _c("td", [_vm._v(" " + _vm._s(_vm.getShipInfo(_vm.form.shipTicket)) + " ")])]), _c("tr", [_c("th", {
    style: _vm.getFirstColStyle
  }, [_vm._v("导游")]), _c("td", [_vm._v(" " + _vm._s(_vm.form.guide) + " ")])])])])]) : _vm._e();
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tac"
  }, [_c("strong", {
    staticClass: "title"
  }, [_vm._v(" 客服房、餐、车预订单 ")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };