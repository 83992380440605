import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import TopTitle from '@/views/print/components/TopTitle';
import { exportTable, calculateAgeByBirth } from '@/utils';
import { SELECTOR } from '@/config';
import { planBoatMembersRequest } from '@/api/plan';
import { Columns, AgeMap } from '../config';
export default {
  components: {
    TopTitle
  },
  props: {
    boatType: {
      type: String,
      default: ''
    },
    columns: {
      type: Array,
      default: Columns
    }
  },
  data() {
    return {
      AgeMap,
      data: null,
      filename: '团队船票名单表',
      totalObj: {},
      dataOrigin: null
    };
  },
  computed: {
    getCardType() {
      return val => {
        if (val == '0') {
          return '身份证';
        }
        const findItem = SELECTOR.cardType.find(it => it.value == val);
        return findItem ? findItem.label : '';
      };
    }
  },
  watch: {
    boatType: {
      handler: function (val) {
        if (!this.dataOrigin) return;
        let data = [...this.dataOrigin];
        if (val != '0' && val != undefined) {
          // 最终名单包含 default + new
          if (val == 'FINAL') {
            data = data.filter(it => it.shipStatus == 'DEFAULT' || it.shipStatus == 'NEW');
          } else {
            data = data.filter(it => it.shipStatus == val);
          }
        }
        this.handleData(data);
      }
    }
  },
  created() {
    const {
      id,
      isRedirect
    } = this.$route.query;
    if (id && !isRedirect) {
      console.log('id:', id);
      this.fetchTeamData(id);
    }
    if (isRedirect) {
      this.fetchBoatMembers({
        id
      });
    }
  },
  methods: {
    // 团队
    fetchTeamData(id) {
      this.$store.dispatch('order/print/fetchTourist', {
        planId: id
      }).then(data => {
        if (!data) return;
        this.handleData(data);
      });
    },
    fetchBoatMembers(params) {
      console.log('params:', params);
      planBoatMembersRequest(params).then(res => {
        console.log('params:', params);
        if (!res || !res.data) return;
        this.dataOrigin = res.data;
        this.handleData(res.data);
      });
    },
    handleData(data) {
      const list = this.formatData(data);
      this.data = list;
      this.formatTotal(list);
      this.$emit('onMounted');
    },
    formatTotal(list) {
      this.totalObj = {
        adult: getTotal(AgeMap.adult, 100),
        half: getTotal(AgeMap.fee, AgeMap.adult),
        fee: getTotal(0, AgeMap.fee)
      };
      function getTotal(minAge, maxAge) {
        return list.reduce((acc, cur) => {
          acc += cur.age >= minAge && cur.age < maxAge ? 1 : 0;
          return acc;
        }, 0);
      }
    },
    formatData(data) {
      return data.map(it => {
        const {
          cardType,
          birthday,
          age
        } = it;
        const newAge = age || calculateAgeByBirth(birthday);
        it.isKid = newAge < AgeMap.fee;
        it.isHalf = newAge >= AgeMap.fee && newAge < AgeMap.adult;
        it.isAdult = newAge > AgeMap.half;
        it.status = '否';
        it.ticket = it.isKid ? '免票' : it.isAdult ? '全票' : '半票';
        it.fee = it.isKid ? '免票' : '';
        it.country = cardType != 1 ? '中国' : '未知';
        it.age = newAge;
        return it;
      });
    },
    setColorStyle(XLSX, wb, sheetName, range) {
      const nums = [];
      const nums2 = [];
      for (let C = range.s.c; C <= range.e.c; ++C) {
        for (let R = range.s.r; R <= range.e.r; ++R) {
          // SHEET行
          let cell = {
            c: C,
            r: R
          }; // 二维 列行确定一个单元格
          let cell_ref = XLSX.utils.encode_cell(cell); // 单元格 A1、A2
          const cur = wb.Sheets[sheetName][cell_ref];
          if (cur.v && !isNaN(cur.v) && cur.v < AgeMap.fee) {
            nums.push(R);
          }
          if (cur.v && !isNaN(cur.v) && cur.v >= AgeMap.fee && cur.v < AgeMap.adult) {
            nums2.push(R);
          }
          if (nums.includes(R)) {
            cur.s = {
              ...cur.s,
              font: {
                sz: 12,
                color: {
                  rgb: 'ff0000'
                }
              },
              fill: {
                fgColor: {
                  rgb: 'ffff00'
                }
              }
            };
          }
          if (nums2.includes(R)) {
            cur.s = {
              ...cur.s,
              font: {
                sz: 12,
                color: {
                  rgb: 'ff0000'
                }
              }
            };
          }
        }
      }
    },
    handleExport() {
      if (!this.data.length) {
        this.$bus.tip({
          type: 'warning',
          message: '无任何数据可导出!'
        });
        return;
      }
      const el = this.$refs.boardTeamRef;
      const reg = /colspan="\d"/g;
      const str = el.innerHTML.replace(reg, '');
      const node = document.createElement('div');
      node.innerHTML = str;
      exportTable(node, this.filename, this.filename, 1, this.setColorStyle);
      node.remove();
    }
  }
};