import "core-js/modules/es.array.reduce.js";
import { getPlanListByResIdRequest } from '@/api/plan';
import { ResourceOrder } from '../tableConfig';
import { OrderStatusTransform } from '../config';
import { PersonCount } from '@/components/common';
import { OrderResPlanEnum } from '@/config/order/orderEnum';
import { DialogShipInfo, DialogHotelInfo, DialogBusInfo, DialogShipName, DialogShipNameEdit } from '.';
export default {
  components: {
    PersonCount,
    DialogShipInfo,
    DialogHotelInfo,
    DialogBusInfo,
    DialogShipName,
    DialogShipNameEdit
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      ResourceOrder,
      orderData: [],
      visible: false
    };
  },
  computed: {
    getExpandClass() {
      return (row, type) => {
        return row[type] ? `${row[type]}-color` : '';
      };
    },
    getRoomCount() {
      return list => {
        return list.reduce((acc, cur) => (acc, acc + cur.count), 0);
      };
    },
    getHotelInfo() {
      return item => {
        return `${item.hotelName || this.data.hotel} `;
        // return `名称：${item.hotelName} 区域：${item.area} 类型：${item.type} 标准：${item.standard} 天数：${item.days} 明细：${item.price}*${item.count}=${item.money}元`
      };
    },
    getShipInfo() {
      return list => {
        return list && list.length && list[0].scenicName;
        // return `${item.shipCompany} ${item.shipType} ${item.ticketType} ${item.scenicName} ${item.money}元`
      };
    },
    getBusInfo() {
      return item => {
        return `${item.busName}`;
        // return `${item.busName} ${item.type} ${item.licenceNumber} ${item.money}元  ${item.remarks} `
      };
    }
  },
  methods: {
    showShipInfo(data) {
      this.$refs.shipRef.show(data);
    },
    showBusInfo(row) {
      const {
        planNumber,
        travelAgencyId
      } = row;
      this.$refs.busRef.show({
        planNumber,
        travelAgencyId,
        shuttleType: 'BUS'
      });
    },
    showHotelInfo(row) {
      const {
        planNumber,
        travelAgencyId
      } = row;
      this.$refs.hotelRef.show({
        planNumber,
        travelAgencyId
      });
    },
    editShipName(row) {
      const data = {
        ...row,
        planId: this.id
      };
      const curResourceItem = this.$store.getters['order/resourcePlan/curResourceItem'];
      if (curResourceItem && data.planId == curResourceItem.planId) {
        this.$refs.shipNameEditRef.show(curResourceItem);
      } else {
        this.$refs.shipNameEditRef.show(data);
        // 设置当前选择的资源
        this.$store.commit('order/resourcePlan/SET_DATA', {
          type: 'curResourceItem',
          data
        });
      }
    },
    showShipName() {
      this.$refs.shipNameRef.show({
        id: this.id
      });
    },
    showOrderData() {
      getPlanListByResIdRequest({
        planResBookingId: this.id
      }).then(res => {
        if (!res || !res.data) return;
        this.orderData = res.data;
      });
    },
    setColorForStatus(row, column) {
      const keys = ['hotelOrder', 'busCompanyOrder', 'scenic', 'restaurant', 'shipOrder'];
      if (row.bookingStatus == OrderResPlanEnum.CANCELED) {
        return 'CANCEL-color';
      } else {
        const key = keys.find(key => column.property == key);
        const status = row[key + 'Status'];
        return OrderStatusTransform[status] ? `${OrderStatusTransform[status]}-color` : '';
      }
    },
    tableCellClass({
      row,
      column
    }) {
      return this.setColorForStatus({
        ...this.data,
        ...row
      }, column);
    }
  }
};