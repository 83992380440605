var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "order-resource-plan"
  }, [_c("SearchBase", {
    on: {
      "on-search": _vm.handleSearch
    },
    scopedSlots: _vm._u([{
      key: "btn",
      fn: function ({}) {
        return [_c("BtnBase", {
          attrs: {
            type: "create"
          },
          on: {
            click: _vm.showDialog
          }
        })];
      }
    }])
  }, [_c("FormItemBase", {
    attrs: {
      label: "团号"
    }
  }, [_c("InputBase", {
    model: {
      value: _vm.searchParams.planNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "planNumber", $$v);
      },
      expression: "searchParams.planNumber"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "发团日期"
    }
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.sendDate,
      label: ""
    },
    on: {
      "on-change": _vm.changeDate
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "客服计调"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.searchParams.outOPUserId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "outOPUserId", $$v);
      },
      expression: "searchParams.outOPUserId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "计划状态"
    }
  }, [_c("SelectBase", {
    attrs: {
      label: "",
      type: "resPlanStatusSearch"
    },
    model: {
      value: _vm.searchParams.bookingStatus,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "bookingStatus", $$v);
      },
      expression: "searchParams.bookingStatus"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "部门"
    }
  }, [_c("InputDepartment", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": function ($event) {
        _vm.searchParams = {
          ..._vm.searchParams,
          ...$event
        };
      }
    },
    model: {
      value: _vm.department,
      callback: function ($$v) {
        _vm.department = $$v;
      },
      expression: "department"
    }
  })], 1)], 1), _c("TipsItem"), _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columns,
      "summary-method": _vm.getSummaries,
      "cell-class-name": _vm.tableCellClass,
      total: _vm.total,
      "show-page": true,
      sticky: true
    },
    on: {
      page: _vm.handlePage
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function ({
        scope
      }) {
        return [_c("PopoverItem", {
          attrs: {
            id: scope.row.id,
            data: scope.row
          }
        })];
      }
    }, {
      key: "date",
      fn: function ({
        scope
      }) {
        return [_c("div", [_vm._v(_vm._s(scope.row.startTripDate))]), _c("div", [_vm._v(_vm._s(scope.row.endTripDate))])];
      }
    }, {
      key: "peopleCount",
      fn: function ({
        scope
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "roomInfo",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm._getRoomInfo(scope.row)) + " ")];
      }
    }, {
      key: "bus",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          on: {
            click: function ($event) {
              return _vm.showBusInfo(scope.row);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.getBusInfo(scope.row)) + " ")])];
      }
    }, {
      key: "restaurant",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.restaurant) + " ")];
      }
    }, {
      key: "shipTicket",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.getShipInfo(scope.row.shipTicket)) + " ")];
      }
    }, {
      key: "hotel",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          on: {
            click: function ($event) {
              return _vm.showHotelInfo(scope.row);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.hotel) + " ")])];
      }
    }, {
      key: "reservation",
      fn: function ({
        scope
      }) {
        return _vm._l(scope.row.hotelReservations, function (item) {
          return _c("div", {
            key: item.id,
            on: {
              click: function ($event) {
                return _vm.showReservationInfo(scope.row, item);
              }
            }
          }, [_vm._v(" " + _vm._s(item.name) + " ")]);
        });
      }
    }, {
      key: "scenic",
      fn: function ({
        scope
      }) {
        return [_c("div", [_vm._v(_vm._s(scope.row.scenic))]), scope.row.fileName ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.downloadFile(scope.row);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.fileName) + " ")]) : _vm._e()];
      }
    }, {
      key: "createTime",
      fn: function ({
        scope
      }) {
        return [_c("div", [_vm._v("首次：" + _vm._s(scope.row.createTime))]), scope.row.updateTime ? _c("div", [_vm._v("更新：" + _vm._s(scope.row.updateTime))]) : _vm._e()];
      }
    }, {
      key: "status",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.getBookingStatus(scope.row.bookingStatus)) + " ")];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [!_vm.isCanceled(scope.row) ? [_vm.canModify(scope.row) ? _c("BtnLink", {
          attrs: {
            type: "success"
          },
          on: {
            click: function ($event) {
              return _vm.handleUpdate(scope.row);
            }
          }
        }, [_vm._v(" 更改 ")]) : _vm._e(), _c("BtnLink", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.handleCopy(scope.row);
            }
          }
        }, [_vm._v(" 复制 ")]), _c("BtnLink", {
          attrs: {
            type: "info"
          },
          on: {
            click: function ($event) {
              return _vm.handleLog(scope.row);
            }
          }
        }, [_vm._v(" 日志 ")]), _c("BtnLink", {
          attrs: {
            type: "warning"
          },
          on: {
            click: function ($event) {
              return _vm.handleCancel(scope.row);
            }
          }
        }, [_vm._v(" 取消 ")])] : _vm._e(), _c("BtnLink", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.handleDetail(scope.row);
            }
          }
        }, [_vm._v(" 查看 ")]), _c("BtnLink", {
          attrs: {
            type: "success"
          },
          on: {
            click: function ($event) {
              return _vm.handleHistory(scope.row);
            }
          }
        }, [_vm._v(" 历史 ")]), _vm.isTeamOrder(scope.row.bookingType) ? _c("BtnLink", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.addOrder(scope.row);
            }
          }
        }, [_vm._v(" 新增订单 ")]) : _vm._e()];
      }
    }])
  }), _c("DialogAdd", {
    ref: "dialogAddRef",
    on: {
      reload: _vm.fetchData
    }
  }), _c("DialogCopy", {
    ref: "dialogCopyRef",
    on: {
      reload: _vm.fetchData
    }
  }), _c("DialogUpdate", {
    ref: "dialogUpdateRef",
    on: {
      reload: _vm.fetchData
    }
  }), _c("DialogDetail", {
    ref: "dialogDetailRef",
    on: {
      reload: _vm.fetchData
    }
  }), _c("DialogHistory", {
    ref: "dialogHistoryRef"
  }), _c("DialogBusInfo", {
    ref: "dialogBusRef"
  }), _c("DialogHotelInfo", {
    ref: "dialogHotelRef"
  }), _c("DialogBookingDetail", {
    ref: "dialogBookingDetailRef"
  }), _c("DialogOrder", {
    ref: "dialogOrderRef"
  }), _c("DialogLog", {
    ref: "logRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };