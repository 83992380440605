import printCompMixin from './printMixin';
import { printMixin } from '@/mixin';
export default {
  mixins: [printMixin, printCompMixin],
  data() {
    return {
      loading: true,
      name: '',
      compMap: {
        Receipt: 'Receipt',
        Receipt2: 'Receipt2',
        Receipt3: 'Receipt3',
        PlanInsurance: 'PlanInsurance',
        GuideBrokerage: 'GuideBrokerage',
        GuideBrokerage2: 'GuideBrokerage2',
        Pickup: 'Pickup',
        Settlement: 'Settlement',
        PlanTeam: 'PlanTeam',
        PlanTeamJzl: 'PlanTeamJzl',
        PlanTeamMhjq: 'PlanTeamMhjq',
        PlanPersonal: 'PlanPersonal',
        PlanPersonalTpl2: 'PlanPersonalTpl2',
        GuideSubmit: 'GuideSubmit',
        GuideSubmit2: 'GuideSubmit2',
        GuidePlanTeam: 'GuidePlanTeam',
        GuidePlanPersonal: 'GuidePlanPersonal',
        PlanShopping: 'PlanShopping',
        PlanTeamName: 'PlanTeamName',
        PlanTeamNameTpl2: 'PlanTeamNameTpl2',
        JYPlanTeam: 'JYPlanTeam',
        PlanPersonalName: 'PlanPersonalName',
        TeamName: 'TeamName',
        BoardNameTeam: 'BoardNameTeam',
        BoardNamePersonal: 'BoardNamePersonal',
        PlanProfitDetail: 'PlanProfitDetail',
        SuperviseTravel: 'SuperviseTravel'
      }
    };
  },
  computed: {
    getComps() {
      return this.compMap[this.name];
    }
  },
  mounted() {
    this.name = this.$route.query.name;
    this.fetchSealImage(this.name, '.print-download');
  },
  methods: {
    handleOnMounted() {
      this.handlePrint();
    },
    handlePrint() {
      // const { mode } = this.$route.query
      // const dir = mode ? 'landscape' : 'portrait'
      const style = document.createElement('style');
      style.innerHTML = '@page { size: a4 auto; margin-top: 1.5cm; margin-bottom: 0.5cm; } @page:first { margin-top: 0.5cm } @page:left { margin-left: 0.75cm; margin-right: 0.75cm } @page:right { margin-left: 0.75cm; margin-right: 0.75cm} ';
      style.media = 'print';
      document.head.appendChild(style);

      // 把标题去掉
      document.title = '';
      setTimeout(() => {
        this.loading = false;
        this.$nextTick(() => {
          window.print();
          window.close();
        });
      }, 1000);
    }
  }
};