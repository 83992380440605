var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "资源计划历史",
      fullscreen: "",
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      cancel: _vm.hide
    }
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columns,
      dynamic: false,
      "show-page": false,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "date",
      fn: function ({
        scope
      }) {
        return [_c("div", [_vm._v(_vm._s(scope.row.startTripDate))]), _c("div", [_vm._v(_vm._s(scope.row.endTripDate))])];
      }
    }, {
      key: "createTime",
      fn: function ({
        scope
      }) {
        return [_c("div", [_vm._v(_vm._s(_vm.getCreateTime(scope.row)))])];
      }
    }, {
      key: "hotel",
      fn: function ({
        scope
      }) {
        return [_c("div", [_vm._v(_vm._s(scope.row.hotel))])];
      }
    }, {
      key: "restaurant",
      fn: function ({
        scope
      }) {
        return [_c("div", [_vm._v(_vm._s(scope.row.restaurant))])];
      }
    }, {
      key: "bus",
      fn: function ({
        scope
      }) {
        return [_c("div", [_vm._v(_vm._s(_vm.getBusInfo(scope.row)))])];
      }
    }, {
      key: "scenic",
      fn: function ({
        scope
      }) {
        return [_c("div", [_vm._v(_vm._s(scope.row.scenic))])];
      }
    }, {
      key: "peopleCount",
      fn: function ({
        scope
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "roomInfo",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm._getRoomInfo(scope.row)) + " ")];
      }
    }, {
      key: "status",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.getBookingStatus(scope.row.bookingStatus)) + " ")];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.handleDetail(scope.row);
            }
          }
        }, [_vm._v(" 查看 ")])];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };