import { getUserData } from '@/utils/cookie';
const getStaffId = () => {
  const {
    id
  } = getUserData();
  return id;
};
export const getRoleName = () => {
  const {
    realName
  } = getUserData();
  return realName;
};
export const getRoomInfo = row => {
  if (!row) return '';
  const {
    hotelStandardCount,
    hotelThreeCount,
    hotelBigCount,
    hotelChildCount,
    hotelRemarks
  } = row;
  const str1 = hotelStandardCount ? hotelStandardCount + '标' : '';
  const str2 = hotelBigCount ? hotelBigCount + '大' : '';
  const str3 = hotelThreeCount ? hotelThreeCount + '三' : '';
  const str4 = hotelChildCount ? hotelChildCount + '亲' : '';
  return `${str1}${str2}${str3}${str4} ${hotelRemarks}`;
};
export const busObjKeyMap = {
  busType: '车型',
  busAge: '车龄',
  useBusCount: '用车数量',
  useBusDays: '用车天数',
  groupType: '团型',
  fee: '金额',
  pick: '接团信息',
  send: '送团信息',
  remarks: '用车要求',
  driverRemarks: '司机'
};
export const busObjItem = () => ({
  busAge: '',
  busType: '',
  driverRemarks: '',
  fee: '',
  groupType: '',
  pick: '',
  remarks: '',
  send: '',
  useBusCount: '',
  useBusDays: ''
});
export const hotelItem = () => ({
  area: '',
  hotel: '',
  hotelId: 0,
  standard: ''
});
export const restaurantItem = () => ({
  breakfast: false,
  dinner: false,
  lunch: false
});
export const scenicItem = () => ({
  orderNumber: 0,
  scenicId: 0,
  scenicName: ''
});
export const shopItem = () => ({
  orderNumber: 0,
  shopId: 0,
  shopName: ''
});
export const DEFAULT_DAYS = 0;
export const genItinerary = ({
  days = DEFAULT_DAYS,
  ...args
} = {}) => {
  return Array.from({
    length: days
  }).map(() => {
    return {
      date: '',
      desc: '',
      hotel: '',
      hotelObj: hotelItem(),
      restaurant: '',
      isPick: false,
      isSend: false,
      restaurantObj: restaurantItem(),
      shopList: [],
      scenicList: [],
      selfScenic: '',
      delShipTicket: false,
      // 是否删除船票
      scenicTempName: '',
      shopTempName: '',
      ...args
    };
  });
};

// 默认表单配置
export const defaultState = () => ({
  accompanyCount: '',
  adultCount: '',
  childCount: '',
  fileName: '',
  filePath: '',
  hotelBigCount: '',
  hotelChildCount: '',
  hotelRemarks: '',
  hotelStandardCount: '',
  hotelThreeCount: '',
  itineraries: genItinerary(),
  bus: '',
  guide: '',
  hotel: '',
  planNumber: '',
  restaurant: '',
  scenic: '',
  source: '',
  outOPUserId: getStaffId(),
  endTripDate: '',
  startTripDate: '',
  ship: {
    course: '正航'
  },
  // 船票信息
  region: [],
  // 区域
  busObj: {}
});

// 新增订单
export const orderState = () => ({
  accompanyCount: 0,
  adultCount: 0,
  childCount: 0,
  customerId: 0,
  customerTripNumber: '',
  dutyOPUserId: 0,
  groupType: '',
  id: 0,
  planCustomerNumber: ''
});
export const rules = {
  customerId: [{
    required: true,
    message: '请选择客户',
    trigger: 'change'
  }],
  dutyOPUserId: [{
    required: true,
    message: '请选择客服计调',
    trigger: 'change'
  }],
  planCustomerNumber: [{
    required: true,
    message: '大团号不能为空',
    trigger: 'blur'
  }],
  adultCount: [{
    required: true,
    message: '大人数不能为空',
    trigger: 'blur'
  }]
};
export const OrderStatusTransform = {
  0: 'NEW',
  1: 'CONFIRMED',
  2: 'ARRANGED',
  true: 'ARRANGED'
};
export const ShipStatusEnum = {
  new: 'NEW',
  cancel: 'CANCEL'
};
export const defaultShipState = () => ({
  isNewAdd: true,
  typeId: 0,
  cardType: 0,
  country: '中国',
  status: '否',
  ticket: '',
  fee: '',
  name: '',
  mobilePhone: '',
  cardNumber: '',
  location: '',
  age: 0,
  sex: 0,
  isContact: false,
  remarks: '',
  shipStatus: ShipStatusEnum.new,
  ignoreShipLockStatus: true
});