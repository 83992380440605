export const Columns = [{
  content: 'age',
  label: '年龄',
  span: 1
}, {
  content: 'name',
  label: '姓名',
  span: 1
}, {
  content: 'cardType',
  label: '证件类型',
  span: 1,
  slot: 'cardType'
}, {
  content: 'cardNumber',
  label: '证件号',
  span: 2
}, {
  content: 'ticket',
  label: '全/半票',
  span: 2
}, {
  content: 'status',
  label: '半票儿童使用监护人证件（全票或半票使用本人证件请选否）',
  span: 2
}, {
  content: 'country',
  label: '国籍/地区（护照等国际证件时必填）',
  span: 2
}, {
  content: 'fee',
  label: '免票',
  span: 1
}];
export const ColumnsPerson = [{
  content: 'serialnumber',
  label: '序号',
  span: 1
}, {
  content: 'customerTripNumber',
  label: '小团号',
  span: 3
}, {
  content: 'peopleCount',
  label: '人数',
  span: 3
}, {
  content: 'age',
  label: '年龄',
  span: 1
}, {
  content: 'name',
  label: '姓名',
  span: 2
}, {
  content: 'cardType',
  label: '证件类型',
  span: 2,
  slot: 'cardType'
}, {
  content: 'cardNumber',
  label: '证件号',
  span: 4
}, {
  content: 'ticket',
  label: '全/半票',
  span: 1
}, {
  content: 'status',
  label: '半票儿童使用监护人证件（全票或半票使用本人证件请选否）',
  span: 3
}, {
  content: 'country',
  label: '国籍/地区（护照等国际证件时必填）',
  span: 3
}, {
  content: 'fee',
  label: '免票',
  span: 1
}];
export const AgeMap = {
  adult: 15,
  half: 14,
  fee: 7
};