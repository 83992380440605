import "core-js/modules/es.array.push.js";
import { parseTime } from '@/utils';
import { genItinerary, getRoleName } from '../config';
import { getShipInfo, formatBusInfo } from '../helper';
export default {
  props: {
    form: {
      type: Object,
      required: true
    },
    isNew: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      getShipInfo,
      firstColWidth: '10%',
      orderTime: parseTime(new Date()),
      timer: null,
      fileName: ''
    };
  },
  computed: {
    getCustomerInfo() {
      return this.form.createUserName || getRoleName;
    },
    getOrderTime() {
      return this.form.createTime || this.orderTime;
    },
    getFirstColStyle() {
      return {
        width: this.firstColWidth
      };
    },
    getBusInfo() {
      const {
        bus,
        busObj
      } = this.form;
      if (JSON.stringify(busObj) != '{}') {
        return formatBusInfo(busObj);
      } else {
        return bus;
      }
    }
  },
  mounted() {
    if (this.isNew) {
      this.timer = setInterval(() => {
        this.orderTime = parseTime(new Date());
      }, 1000);
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    addItinerary() {
      this.form.itineraries.push(...genItinerary(1));
    },
    downloadFile() {
      const {
        filePath,
        fileName
      } = this.form;
      this.$store.dispatch('resource/common/getOssImage', filePath).then(res => {
        if (!res) return;
        const [url] = res;
        this.$store.dispatch('order/resourcePlan/downloadFile', {
          url,
          fileName
        });
      });
    }
  }
};