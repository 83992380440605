import "core-js/modules/es.array.push.js";
import { ColumnsShipNameEdit as Columns } from '../tableConfig';
import { defaultShipState, ShipStatusEnum } from '../config';
import { planBoatMembersRequest } from '@/api/plan';
import { createOrUpdateTourist } from '@/api/order';
import { setShipLockStatusRequest, setShipTicketStatusRequest } from '@/api/plan/ship';
import { formatPeopleValue, getCardTypeLabel, getIdCardInfo, getDictionaryList } from '@/utils';
import { SelectDictionary } from '@/components/common';
const AgeMap = {
  old: 65,
  halfOld: 60,
  adult: 15,
  half: 14,
  fee: 7
};
const TicketStatus = {
  no: 0,
  // '未出'，
  wait: 1,
  // '待出'票，
  yes: 2 // '已出'票
};
export default {
  components: {
    SelectDictionary
  },
  data() {
    return {
      Columns,
      ShipStatusEnum,
      visible: false,
      tableData: []
    };
  },
  computed: {
    curRow() {
      return this.$store.getters['order/resourcePlan/curResourceItem'];
    },
    isShipTicketStatus() {
      return this.curRow && this.curRow.shipTicketStatus;
    },
    isShipTicketStatusNo() {
      return this.isShipTicketStatus == TicketStatus.no;
    },
    isShipTicketStatusWait() {
      return this.isShipTicketStatus == TicketStatus.wait;
    },
    isShipTicketStatusConfirm() {
      return this.isShipTicketStatus == TicketStatus.yes;
    },
    deleteBtnDisabled() {
      return row => row.shipStatus != ShipStatusEnum.cancel && !row.isNewAdd;
    },
    canTicketOut() {
      return !this.curRow.shipNameListStatus || this.isShipTicketStatusWait || this.isShipTicketStatusConfirm;
    }
  },
  methods: {
    fetchBoatMembers(params) {
      planBoatMembersRequest(params).then(res => {
        if (!res || !res.data) return;
        this.tableData = this.formatData(res.data);
      });
    },
    formatData(data) {
      const [full, half, halfOld] = getDictionaryList(this.$store, 'shipTicketType');
      return data.map((it, idx) => {
        const {
          cardType,
          age
        } = it;
        const newAge = age;
        const isKid = newAge < AgeMap.fee;
        const isHalf = newAge >= AgeMap.fee && newAge < AgeMap.adult;
        const isHalfOld = newAge >= halfOld && newAge < AgeMap.old;
        const isAdult = newAge > AgeMap.half && newAge < AgeMap.old;
        const isOld = newAge >= AgeMap.old;
        let ticket = '免票';
        if (isHalf || isHalfOld) {
          ticket = half.name;
        }
        if (isOld) {
          ticket = halfOld.name;
        }
        if (isAdult) {
          ticket = full.name;
        }
        it.orderNumber = idx + 1;
        it.status = '否';
        it.fee = isKid ? '免票' : '';
        it.country = cardType != 1 ? '中国' : '未知';
        it.age = newAge;
        it.peopleCount = formatPeopleValue(it);
        it.$cardType = getCardTypeLabel(it.cardType);
        // 默认下舱
        it.shipTicketType = it.shipTicketType || '下舱';
        return {
          ...it,
          isKid,
          isHalf,
          isAdult,
          shippingSpace: ticket
        };
      });
    },
    handleAdd() {
      const first = this.tableData[0];
      const item = {
        ...defaultShipState(),
        customerOrderId: first.customerOrderId,
        orderNumber: ++this.tableData.length
      };
      this.tableData.push(item);
    },
    handleDel() {},
    setLockStatus() {
      if (!this.tableData.length) return;
      this.$bus.open(() => {
        const status = !this.curRow.shipNameListStatus;
        setShipLockStatusRequest({
          id: this.curRow.id,
          status
        }).then(() => {
          // 设置名单锁定状态
          this.curRow.shipNameListStatus = status;
          this.$bus.tip();
        });
      }, '确定设置锁定状态吗');
    },
    setTicketStatus(status, message) {
      this.$bus.open(() => {
        setShipTicketStatusRequest({
          id: this.curRow.id,
          status
        }).then(() => {
          // 出票状态
          this.curRow.shipTicketStatus = status;
          this.$bus.tip();
        });
      }, message);
    },
    setTicketStatusConfirm() {
      this.setTicketStatus(TicketStatus.wait, '是否最终名单？一旦确认出票，损失将自行承担！！！');
    },
    setTicketStatusCancel() {
      this.setTicketStatus(TicketStatus.no, '确定取消出票吗？');
    },
    changeCardNumber(row, val) {
      const useInfo = getIdCardInfo(val);
      let {
        addr: location,
        birth
      } = useInfo;
      if (!birth) return;
      const [y,,,] = birth.split('-');
      const date = new Date();
      const year = date.getFullYear();
      const age = Number(year) - Number(y);
      const isKid = age < AgeMap.fee;
      const isAdult = age > AgeMap.half;
      row.age = age;
      row.location = location;
      row.shippingSpace = isKid ? '免票' : isAdult ? '全票' : '半票';
    },
    setResourceItem() {
      const data = this.curRow;
      this.$store.commit('order/resourcePlan/SET_DATA', {
        type: 'curResourceItem',
        data
      });
    },
    show(row) {
      // 设置状态
      this.fetchBoatMembers({
        id: row.planId
      });
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.tableData = [];
    },
    delOne(row) {
      const extra = {
        shipStatus: ShipStatusEnum.cancel,
        ignoreShipLockStatus: true
      };
      createOrUpdateTourist([{
        ...row,
        ...extra
      }]).then(() => {
        this.fetchBoatMembers({
          id: this.curRow.planId
        });
      });
    },
    saveOne(row) {
      createOrUpdateTourist([row]).then(() => {
        this.fetchBoatMembers({
          id: this.curRow.planId
        });
      });
    },
    handleSave() {
      const optList = this.tableData.filter(it => it != null);
      createOrUpdateTourist(optList).then(() => {
        this.$bus.tip();
        this.hide();
      });
    }
  }
};