import { reloadMixin } from '@/mixin';
import TableDictionary from './TableDictionary';
export default {
  components: {
    TableDictionary
  },
  mixins: [reloadMixin],
  data() {
    return {
      tabId: '',
      activeName: '',
      tabIndex: 0,
      tabList: []
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store.dispatch('system/dictionary/fetchTypeList').then(data => {
        if (!data) return;
        const filterData = this.filterBusSystemDictionary(data);
        this.tabList = filterData;
        this.findIndexByType(filterData);
      });
    },
    fetchDetail(id) {
      const loading = this.$bus.loading();
      this.$store.dispatch('system/dictionary/fetchList', id).then(() => {
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    filterBusSystemDictionary(data) {
      return data.filter(it => it.code.indexOf('bus:') < 0);
    },
    setDefaultTab(data, initTab = 0) {
      const {
        id,
        name
      } = data[initTab];
      this.activeName = name + initTab;
      this.tabId = id;
      this.fetchDetail(id);
    },
    tabChange(tab) {
      const {
        id
      } = this.tabList[tab.index];
      this.tabIndex = tab.index;
      this.tabId = id;
      this.fetchDetail(id);
    },
    findIndexByType(data) {
      const {
        type
      } = this.$route.query;
      const idx = data.findIndex(it => it.code === type);
      this.setDefaultTab(data, type ? idx : 0);
    },
    triggleTab() {
      const {
        id
      } = this.tabList[this.tabIndex];
      this.tabId = id;
      this.fetchDetail(id);
    }
  }
};