var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "table-native"
  }, [_c("TopTitle", {
    attrs: {
      logo: _vm.logo,
      title: _vm.title
    }
  }), _vm._t("tableHead"), _vm.isVertical ? _c("table", [_c("colgroup", _vm._l(_vm.colLen, function (item) {
    return _c("col", {
      key: item
    });
  }), 0), _c("tbody", _vm._l(_vm.getColumns, function (col, colIndex) {
    return _c("tr", {
      key: colIndex
    }, [_vm._l(col.rows, function (item, index) {
      return [item.colspan == "all" ? _c("th", {
        key: "th" + index,
        attrs: {
          colspan: _vm.colLen
        }
      }, [_vm._v(" " + _vm._s(item.attrs.label) + " ")]) : [_c("th", _vm._b({
        key: index,
        style: {
          width: _vm.labelWidth || _vm.minWidth
        }
      }, "th", {
        ...item.attrs
      }, false), [_vm._v(" " + _vm._s(item.attrs.label) + " ")]), item.slot ? _c("td", _vm._b({
        key: "slot" + index,
        style: {
          width: _vm.labelWidth || _vm.minWidth
        }
      }, "td", {
        ...item.attrs,
        ...(item.content || {})
      }, false), [_vm._t(item.slot, null, {
        row: _vm.getData
      })], 2) : [_c("td", _vm._b({
        key: "con" + index,
        attrs: {
          colspan: _vm.getColSpan(col.rows)
        }
      }, "td", {
        ...item.attrs,
        ...(item.content || {})
      }, false), [_c("span", [_vm._v(_vm._s(_vm.getData ? _vm.getData[item.attrs.prop] : ""))])])]]];
    })], 2);
  }), 0)]) : _c("table", [_c("colgroup", _vm._l(_vm.colLen, function (item) {
    return _c("col", {
      key: item
    });
  }), 0), _c("thead", [_vm.getTableHead ? _vm._l(_vm.getHeadCount, function (th, i) {
    return _c("tr", {
      key: i
    }, [_vm._l(_vm.getColumns, function (item, index) {
      return [!item.attrs.children && i == 0 ? _c("th", _vm._b({
        key: index + i,
        attrs: {
          rowspan: _vm.getHeadCount
        }
      }, "th", {
        align: _vm.align,
        ...item.attrs
      }, false), [_c("span", [_vm._v(_vm._s(item.attrs.label))])]) : [i == 0 ? [_c("th", _vm._b({
        key: index,
        attrs: {
          colspan: item.attrs.children.length
        }
      }, "th", {
        align: _vm.align,
        ...item.attrs
      }, false), [_c("span", [_vm._v(_vm._s(item.attrs.label))])])] : _vm._l(item.attrs.children, function (child) {
        return _c("th", _vm._b({
          key: child.attrs.label
        }, "th", {
          align: _vm.align,
          ...child.attrs
        }, false), [_c("span", [_vm._v(_vm._s(child.attrs.label))])]);
      })]];
    })], 2);
  }) : _c("tr", _vm._l(_vm.getColumns, function (item, index) {
    return _c("th", _vm._b({
      key: index
    }, "th", {
      align: _vm.align,
      ...item.attrs
    }, false), [_c("span", [_vm._v(_vm._s(item.attrs.label))])]);
  }), 0)], 2), _vm._l(_vm.data, function (val, index) {
    return _c("tr", {
      key: index
    }, [_vm._l(_vm.columns, function (item, itemIndex) {
      return [_vm.getTableHead && item.attrs.children ? [_vm._l(item.attrs.children, function (child, childIndex) {
        return [child.slot ? _c("td", _vm._b({
          key: childIndex + itemIndex
        }, "td", {
          align: _vm.align,
          ...child.attrs,
          ...(child.content || {})
        }, false), [_vm._t(child.slot, null, {
          row: val
        })], 2) : _c("td", _vm._b({
          key: childIndex + itemIndex
        }, "td", {
          align: _vm.align,
          ...child.attrs,
          ...(child.content || {})
        }, false), [_vm._v(" " + _vm._s(val ? val[child.attrs.prop] : "") + " ")])];
      })] : [item.slot ? _c("td", _vm._b({
        key: itemIndex
      }, "td", {
        align: _vm.align,
        ...item.attrs,
        ...(item.content || {})
      }, false), [_vm._t(item.slot, null, {
        row: val
      })], 2) : _c("td", _vm._b({
        key: itemIndex
      }, "td", {
        align: _vm.align,
        ...item.attrs,
        ...(item.content || {})
      }, false), [_vm._v(" " + _vm._s(val ? val[item.attrs.prop] : "") + " ")])]];
    })], 2);
  })], 2), _vm._t("tableFoot")], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };