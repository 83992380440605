import { getFileUrl } from '@/api';
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    logo: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      logoUrl: ''
    };
  },
  computed: {
    getLogo() {
      return this.logoUrl;
    }
  },
  watch: {
    logo: {
      handler(val) {
        val && this.fetchLogoUrl(val);
      },
      immediate: true
    }
  },
  methods: {
    async fetchLogoUrl(logoUrl) {
      const result = await getFileUrl([logoUrl]);
      if (result) {
        const path = result.data[0];
        this.logoUrl = path;
      }
    }
  }
};