import SearchUnit from '../../components/SearchUnit';
import TableList from './TableList';
import { SelectCustom } from '@/components/common';
import { downBillUnitTeamFund } from '@/config/billDown';
import { excelMixin, reloadMixin } from '@/mixin';
import { getCurTagView } from '@/utils/tagView';
export default {
  components: {
    SearchUnit,
    TableList,
    SelectCustom
  },
  mixins: [excelMixin, reloadMixin],
  data() {
    return {
      path: '/bill/unit-collect/team-create',
      tableData: [],
      columnData: downBillUnitTeamFund,
      total: 0,
      resourceId: '',
      statistic: null,
      filename: '单位收款',
      autoWidth: true,
      bookType: 'xlsx',
      excelLoading: false
    };
  },
  computed: {
    teamFund() {
      return this.$store.getters['bill/billDown/team'];
    },
    getTagViewSearch() {
      const curTag = getCurTagView();
      return curTag ? curTag.search : this.searchParams;
    }
  },
  watch: {
    teamFund: {
      handler: function (data) {
        !data ? this.fetchData({
          startCashDate: this.getToday,
          endCashDate: this.getToday
        }) : this.handleData(data);
      },
      immediate: true
    },
    getTagViewSearch: {
      handler: function (search) {
        if (!search) return;
        this.resourceId = search.resourceId;
      },
      immediate: true
    }
  },
  mounted() {
    if (this.$route.query.isBack) {
      this.reload();
    }
  },
  methods: {
    fetchData(params = {}) {
      this.$store.dispatch('bill/billDown/fetchTeamFund', params);
    },
    reload() {
      const params = this.$refs.searchRef.searchParams;
      this.fetchData(params);
    },
    handleData(data) {
      const {
        list,
        totalCount,
        statistic
      } = data;
      this.tableData = list;
      this.total = totalCount;
      this.statistic = statistic;
    },
    selectResource(val) {
      const {
        id: resourceId
      } = val;
      this.resourceId = resourceId;
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      const params = this.$refs.searchRef.searchParams;
      this.fetchData({
        ...params,
        pageNum,
        pageSize,
        resourceId: this.resourceId
      });
    },
    // 搜索
    handleSearch(searchParams) {
      this.fetchData(searchParams);
    }
  }
};