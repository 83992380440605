import { OrderResourcePlanHistory as columns } from '../tableConfig';
import { OrderResPlanEnumLabel } from '@/config/order/orderEnum';
import { PersonCount } from '@/components/common';
import { getRoomInfo } from '../config';
import { formatBusInfo } from '../helper';
export default {
  components: {
    PersonCount
  },
  data() {
    return {
      columns,
      visible: false,
      tableData: []
    };
  },
  computed: {
    _getRoomInfo() {
      return row => getRoomInfo(row);
    },
    getBookingStatus() {
      return val => val ? OrderResPlanEnumLabel[val] : '';
    },
    getCreateTime() {
      return row => {
        const {
          storyCreateTime,
          createTime,
          masterCreateTime
        } = row;
        return storyCreateTime || createTime || masterCreateTime;
      };
    },
    getBusInfo() {
      return row => {
        const {
          bus,
          busObj
        } = row;
        if (JSON.stringify(busObj) != '{}') {
          return formatBusInfo(row.busObj);
        } else {
          return bus;
        }
      };
    }
  },
  methods: {
    show(masterId) {
      this.visible = true;
      this.fetchData({
        masterId
      });
    },
    hide() {
      this.visible = false;
    },
    fetchData(params) {
      this.$store.dispatch('order/resourcePlan/fetchHistory', params).then(data => {
        if (!data) return;
        this.tableData = data.list;
      });
    },
    handleDetail(row) {
      this.$parent.handleDetail(row);
    }
  }
};